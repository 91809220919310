import "./styles.css";
import ChildCareFormv1 from "./pages/ChildCareFormv1";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { FaLaptopCode, FaChalkboardTeacher, FaBook, FaRobot, FaDatabase, FaCode, FaServer } from "react-icons/fa";
const Card = ({ children }) => (
  <div className="border rounded-lg shadow-md p-4">{children}</div>
);

const CardContent = ({ children }) => <div className="p-2">{children}</div>;

const Button = ({ children, asChild, variant }) => (
  <button className={`button ${variant === "outline" ? "border border-gray-600 bg-white text-black" : ""}`}>
    {children}
  </button>
);

const Home = () => (
  <div className="p-8 text-center">
    <h1 className="text-4xl font-bold mb-4">Welcome to TechTraining.io</h1>
    <p className="text-lg mb-6">Your hub for high-quality technical training.</p>
    <div className="flex justify-center gap-4">
      <Button asChild>
        <Link to="/courses">Browse Courses</Link>
      </Button>
      <Button variant="outline" asChild>
        <Link to="/about">Learn More</Link>
      </Button>
    </div>
    {/* Added Image Below the Welcome Section */}
    <div className="flex justify-center mt-6">
      <img
        src="/images/PrimaryHome.jpg"
        alt="TechTraining Branding"
        className="w-full max-w-3xl rounded-lg shadow-lg"
      />
    </div>
  </div>
);

const Courses = () => (
  <div className="container">
    <h2 className="text-3xl font-bold mb-4">Courses</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {[{name: "Web Development", icon: <FaLaptopCode />},
        {name: "Cybersecurity", icon: <FaChalkboardTeacher />},
        {name: "Cloud Computing", icon: <FaBook />},
        {name: "AI Agents", icon: <FaRobot />},
        {name: "Database Management (DBMS)", icon: <FaDatabase />},
        {name: "Scripting", icon: <FaCode />},
        {name: "Web Servers", icon: <FaServer />}].map((course, index) => (
        <div key={index} className="card">
          <div className="text-4xl mx-auto mb-2">{course.icon}</div>
          <h3 className="text-xl font-semibold">{course.name}</h3>
        </div>
      ))}
    </div>
  </div>
);

const About = () => (
  <div className="p-8 text-center">
    <h2 className="text-3xl font-bold mb-4">About Us</h2>
    <p className="text-lg">TechTraining.io provides structured technical training in various fields to help professionals upskill and advance in their careers.</p>
  </div>
);

const Contact = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Thank you for contacting us! We will get back to you soon.");
  };
  return (
    <div className="p-8 text-center">
      <h2 className="text-3xl font-bold mb-4">Contact Us</h2>
      <p className="text-lg mb-4">Have questions? Reach out to us using the form below or via email at <a href="mailto:support@techtraining.io" className="text-blue-500">support@techtraining.io</a></p>
      <form onSubmit={handleSubmit} className="max-w-lg mx-auto text-left">
        <div className="mb-4">
          <label className="block font-semibold">Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} className="w-full p-2 border rounded" required />
        </div>
        <div className="mb-4">
          <label className="block font-semibold">Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} className="w-full p-2 border rounded" required />
        </div>
        <div className="mb-4">
          <label className="block font-semibold">Message:</label>
          <textarea name="message" value={formData.message} onChange={handleChange} className="w-full p-2 border rounded" required></textarea>
        </div>
        <Button type="submit">Send Message</Button>
      </form>
    </div>
  );
};


const Dashboard = () => (
  <div className="p-8 text-center">
    <h2 className="text-3xl font-bold mb-4">User Dashboard</h2>
    <p className="text-lg">Track your enrolled courses, progress, and certifications.</p>
  </div>
);
const App = () => {
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <nav className="navbar">
          <Link to="/" className="text-xl font-bold">TechTraining.io</Link>
          <div>
            <Link to="/courses">Courses</Link>
            <Link to="/interactive">Interactive Learning</Link>
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/about">About</Link>
            <Link to="/contact">Contact</Link>
            <Link to="/child-care-form" className="hover:underline">Child Care Form</Link>
          </div>
        </nav>
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/interactive" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/child-care-form" element={<ChildCareFormv1 />} />
          </Routes>
        </main>
        <footer className="footer">
          &copy; 2025 TechTraining.io - All Rights Reserved.
        </footer>
      </div>
    </Router>
  );
};

export default App;
