import { useState } from "react";

const ChildCareForm = () => {
    const [formData, setFormData] = useState({
        applicant_name: "",
        location_address: "",
        email: "",
        website: "",
        risk_management_contact: "",
        years_in_business: "",
        building_type: "",
        building_type_other: "",
        hours_of_operation: "",
        licensed: "",
        licensing_exemption: "",
        direct_exit: "",
        bathroom_locks: "",
        infants_children: "",
        infants_teachers: "",
        toddlers_children: "",
        toddlers_teachers: "",
        preschool_children: "",
        preschool_teachers: "",
        security_personnel: "",
        security_type: [],
        evacuation_plan: "",
        transportation: "",
        special_classes: "",
        swimming_pools: "",
        pool_location: "",
        comments: ""
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox") {
            setFormData((prev) => ({
                ...prev,
                [name]: checked
                    ? [...(prev[name] || []), value]
                    : prev[name].filter((v) => v !== value),
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data Submitted:", formData);
        // Send formData to API/backend here
    };

    return (
        <div className="max-w-3xl mx-auto bg-white p-6 shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">Child Care Center Supplemental Application</h2>
            <form onSubmit={handleSubmit} className="space-y-4">

                {/* General Information */}
                <div>
                    <label className="block text-gray-600">Applicant Name</label>
                    <input type="text" name="applicant_name" value={formData.applicant_name} onChange={handleChange} className="w-full p-2 border rounded-md" required />
                </div>

                <div>
                    <label className="block text-gray-600">Location Address</label>
                    <input type="text" name="location_address" value={formData.location_address} onChange={handleChange} className="w-full p-2 border rounded-md" required />
                </div>

                <div>
                    <label className="block text-gray-600">Email</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} className="w-full p-2 border rounded-md" required />
                </div>

                <div>
                    <label className="block text-gray-600">Website</label>
                    <input type="url" name="website" value={formData.website} onChange={handleChange} className="w-full p-2 border rounded-md" />
                </div>

                <div>
                    <label className="block text-gray-600">Years in Business</label>
                    <input type="number" name="years_in_business" value={formData.years_in_business} onChange={handleChange} className="w-full p-2 border rounded-md" />
                </div>

                {/* Building Type */}
                <div>
                    <label className="block text-gray-600">Building Type</label>
                    <select name="building_type" value={formData.building_type} onChange={handleChange} className="w-full p-2 border rounded-md">
                        <option value="">Select</option>
                        <option value="commercial">Commercial</option>
                        <option value="church">Church</option>
                        <option value="school">School</option>
                        <option value="other">Other</option>
                    </select>
                    {formData.building_type === "other" && (
                        <input type="text" name="building_type_other" value={formData.building_type_other} onChange={handleChange} className="w-full p-2 border rounded-md mt-2" placeholder="Specify other" />
                    )}
                </div>

                {/* Licensing */}
                <div>
                    <label className="block text-gray-600">Is the child care center licensed?</label>
                    <select name="licensed" value={formData.licensed} onChange={handleChange} className="w-full p-2 border rounded-md">
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>

                <div>
                    <label className="block text-gray-600">If NO, why is the center exempt from licensing?</label>
                    <textarea name="licensing_exemption" value={formData.licensing_exemption} onChange={handleChange} className="w-full p-2 border rounded-md"></textarea>
                </div>

                {/* Security */}
                <div>
                    <label className="block text-gray-600">Is there security personnel on-site?</label>
                    <select name="security_personnel" value={formData.security_personnel} onChange={handleChange} className="w-full p-2 border rounded-md">
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>

                <div>
                    <label className="block text-gray-600">Security Type</label>
                    <div className="flex flex-wrap gap-2">
                        <label><input type="checkbox" name="security_type" value="subcontracted" onChange={handleChange} /> Subcontracted</label>
                        <label><input type="checkbox" name="security_type" value="employed" onChange={handleChange} /> Employed</label>
                        <label><input type="checkbox" name="security_type" value="other" onChange={handleChange} /> Other</label>
                    </div>
                </div>

                {/* Special Activities */}
                <div>
                    <label className="block text-gray-600">Are special classes provided?</label>
                    <textarea name="special_classes" value={formData.special_classes} onChange={handleChange} className="w-full p-2 border rounded-md"></textarea>
                </div>

                {/* Swimming Pools */}
                <div>
                    <label className="block text-gray-600">Does the center use swimming pools?</label>
                    <select name="swimming_pools" value={formData.swimming_pools} onChange={handleChange} className="w-full p-2 border rounded-md">
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>

                {/* Comments */}
                <div>
                    <label className="block text-gray-600">Additional Comments</label>
                    <textarea name="comments" value={formData.comments} onChange={handleChange} className="w-full p-2 border rounded-md"></textarea>
                </div>

                {/* Submit Button */}
                <button type="submit" className="w-full bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600">
                    Submit Application
                </button>
            </form>
        </div>
    );
};

export default ChildCareForm;
